import * as React from "react";
import ContactsPageSection from "../components/sections/ContactsPageSection";
import PageTitle from "../components/elements/PageTitle";
import Footer from "../components/sections/Footer";
import Header from "../components/sections/Header";

const ContactsPage = () => (
  <div className="bg-white">
    <PageTitle
      pageTitle={"Contacts with us"}
      description={`We’d love to hear from you! Send us a message using the form opposite, or email us. Org. No: 21776378
      Address: Zmaj Jovina st. 26 sp.1 st.102 21101 Novi Sad, Serbia`}
    />
    <Header />
    <ContactsPageSection
      title={"Get in touch"}
      // description={`Convallis feugiat et aliquet pellentesque dictum nisi,
      // velit. Egestas fermentum adipiscing risus quam ac consectetur mattis
      // turpis tristique`}
      imgFileName={""}
      // TODO: delete it and use ONLY imgFileName
      // when it is loaded into the project
      imgUrl={
        "https://images.unsplash.com/photo-1556761175-4b46a572b786?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1567&q=80"
      }
      formTitle={"Let's work together"}
      formDescription={`We’d love to hear from you! Send us a message
      using the form below, or e-mail us.`}
      firstNameLabel={"First name"}
      lastNameLabel={"Last name"}
      emailLabel={"Email"}
      companyLabel={"Company"}
      phoneLabel={"Phone"}
      messageLabel={"How can we help you?"}
      expectedBudgetLabel={"Expected budget"}
      howDidYouHearAboutUsLabel={"How did you hear about us?"}
      submitButton={"Submit"}
      submitButtonSuccess={"Sent"}
      ctaTitle={"Looking for a new career?"}
      ctaDescription={"We’re hiring."}
      showMoreText={"See open positions"}
      showMorePath={"/en/join/"}
    />
    <Footer />
  </div>
);

export default ContactsPage;
