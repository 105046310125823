import React, { useState, useEffect, createRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { ChevronRightIcon, CheckIcon } from "@heroicons/react/solid";
import { Link, useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { initialFormFields } from "../utils/utils";
import Title from "../elements/Title";
import { bearerToken, captchaInfoUrl, sendMailerUrl } from "../../api/mailer";

const ContactsPageSection = ({
  title,
  description,
  imgFileName,
  imgUrl,
  formTitle,
  formDescription,
  showMorePath,
  showMoreText,
  firstNameLabel,
  lastNameLabel,
  emailLabel,
  companyLabel,
  phoneLabel,
  messageLabel,
  expectedBudgetLabel,
  howDidYouHearAboutUsLabel,
  submitButton,
  submitButtonSuccess,
  ctaTitle,
  ctaDescription,
}) => {
  const recaptchaRef = createRef();

  const contactFormFields = {
    ...initialFormFields,
    firstName: "",
    lastName: "",
    companyName: "",
    howDidHear: "",
    expectedBudget: "",
  };
  const [formFields, setFormFields] = useState(contactFormFields);
  const [formIsSent, setFormIsSent] = useState(false);

  const [img, setImg] = useState("");
  const [imgNode, setImgNode] = useState({});

  const [siteKey, setSiteKey] = useState("");
  const [, setSiteUrl] = useState("");

  const [isVerified, setIsVerified] = useState(false);

  const getCaptchaInfo = async () => {
    const resp = await fetch(captchaInfoUrl, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + bearerToken,
      },
    }).catch((err) => {
      new Error(err);
    });

    const data = await resp.json();

    setSiteKey(data.site_key);
    setSiteUrl(data.site_url);
  };

  useEffect(() => {
    getCaptchaInfo();
  }, []);

  const data = useStaticQuery(graphql`
    query {
      allImageSharp {
        edges {
          node {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
              originalName
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    setImgNode(
      data.allImageSharp.edges.find((e) =>
        e.node.fluid.originalName.includes(imgFileName)
      )
    );
  }, [data]);

  useEffect(() => {
    if (imgNode && imgNode.node && imgNode.node.fluid) {
      setImg(imgNode.node.fluid);
    }
  }, [imgNode]);

  const setFirstName = (event) => {
    setFormFields({ ...formFields, firstName: event.target.value });
  };
  const setLastName = (event) => {
    setFormFields({ ...formFields, lastName: event.target.value });
  };
  const setEmail = (event) => {
    setFormFields({ ...formFields, email: event.target.value });
  };
  const setCompanyName = (event) => {
    setFormFields({ ...formFields, companyName: event.target.value });
  };
  const setPhone = (event) => {
    setFormFields({ ...formFields, phone: event.target.value });
  };
  const setMessage = (event) => {
    setFormFields({ ...formFields, message: event.target.value });
  };
  const setHowDidHear = (event) => {
    setFormFields({ ...formFields, howDidHear: event.target.value });
  };
  const setExpectedBudget = (event) => {
    setFormFields({ ...formFields, expectedBudget: event.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const recaptchaValue = recaptchaRef.current.getValue();

    fetch(sendMailerUrl, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + bearerToken,
      },
      body: JSON.stringify({
        firstName: formFields.firstName,
        lastName: formFields.lastName,
        email: formFields.email,
        companyName: formFields.companyName,
        phone: formFields.phone,
        message: formFields.message,
        howDidHear: formFields.howDidHear,
        expectedBudget: formFields.expectedBudget,
        subject: "Let's work together",

        recaptcha: recaptchaValue,
      }),
    })
      .catch((err) => {
        new Error(err);
        setFormIsSent(false);
      })
      .then((res) => {
        setFormIsSent(true);
        setFormFields(contactFormFields);
      });
  };
  return (
    <section className="bg-white">
      {/* Header */}
      <Title title={title} description={description} />
      {/* Contact Section */}
      <div className="relative bg-white">
        <div className="lg:absolute lg:inset-0">
          <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            {imgUrl ? (
              <img
                className="h-56 w-full object-cover lg:absolute lg:h-full"
                src={imgUrl}
                alt=""
              />
            ) : (
              <>
                {img && (
                  <Img
                    fluid={img}
                    alt=""
                    className="h-56 w-full object-cover lg:absolute lg:h-full"
                  />
                )}
              </>
            )}
          </div>
        </div>
        <div className="relative py-12 px-4 sm:py-24 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:py-32 lg:grid lg:grid-cols-2">
          <div className="lg:pr-8">
            <div className="max-w-md mx-auto sm:max-w-lg lg:mx-0">
              {/* Form title */}
              <h2 className="text-3xl font-extrabold text-blue-gray-900 tracking-tight sm:text-4xl">
                {formTitle}
              </h2>
              {/* Form description */}
              <p className="mt-4 text-lg text-gray-500 sm:mt-3">
                {formDescription}
              </p>
              {/* Contact form */}
              <form
                method="POST"
                onSubmit={onSubmit}
                className="mt-9 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
              >
                <div>
                  <div className="flex justify-between">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {firstNameLabel}
                    </label>
                    <span id="first-name" className="text-md text-red-500">
                      *
                    </span>
                  </div>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="first-name"
                      id="first-name"
                      autoComplete="given-name"
                      onChange={setFirstName}
                      required
                      className="w-full shadow-md border-white px-5 py-3 placeholder-warm-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-amber-500 focus:ring-white rounded-md"
                    />
                  </div>
                </div>
                <div>
                  <div className="flex justify-between">
                    <label
                      htmlFor="last-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {lastNameLabel}
                    </label>
                    <span id="last-name" className="text-md text-gray-500">
                      Optional
                    </span>
                  </div>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="last-name"
                      id="last-name"
                      autoComplete="family-name"
                      onChange={setLastName}
                      className="w-full shadow-md border-white px-5 py-3 placeholder-warm-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-amber-500 focus:ring-white rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <div className="flex justify-between">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {emailLabel}
                    </label>
                    <span id="email" className="text-md text-red-500">
                      *
                    </span>
                  </div>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      onChange={setEmail}
                      className="w-full shadow-md border-white px-5 py-3 placeholder-warm-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-amber-500 focus:ring-white rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="company"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {companyLabel}
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="company"
                      id="company"
                      autoComplete="organization"
                      onChange={setCompanyName}
                      className="w-full shadow-md border-white px-5 py-3 placeholder-warm-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-amber-500 focus:ring-white rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <div className="flex justify-between">
                    <label
                      htmlFor="phone"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {phoneLabel}
                    </label>
                    <span
                      id="phone-description"
                      className="text-md text-gray-500"
                    >
                      Optional
                    </span>
                  </div>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      autoComplete="tel"
                      aria-describedby="phone-description"
                      onChange={setPhone}
                      className="w-full shadow-md border-slate-900 px-5 py-3 placeholder-warm-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-amber-500 focus:ring-white rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <div className="flex justify-between">
                    <label
                      htmlFor="how-can-we-help"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {messageLabel}
                    </label>
                    <span
                      id="how-can-we-help-description"
                      className="text-sm text-gray-500"
                    >
                      Max. 500 characters
                    </span>
                  </div>
                  <div className="mt-1">
                    <textarea
                      id="how-can-we-help"
                      name="how-can-we-help"
                      aria-describedby="how-can-we-help-description"
                      rows={4}
                      onChange={setMessage}
                      className="w-full shadow-md border-slate-900 px-5 py-3 placeholder-warm-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-amber-500 focus:ring-white rounded-md"
                      defaultValue={""}
                    />
                  </div>
                </div>
                <fieldset className="sm:col-span-2">
                  <legend className="block text-sm font-medium text-gray-700">
                    {expectedBudgetLabel}
                  </legend>
                  <div className="mt-4 grid grid-cols-1 gap-y-4">
                    <div className="flex items-center">
                      <input
                        id="budget-under-25k"
                        name="budget"
                        defaultValue="under_25k"
                        type="radio"
                        onBlur={setExpectedBudget}
                        className="focus:ring-amber-500 h-4 w-4 text-amber-600 border-gray-300"
                      />
                      <label htmlFor="budget-under-25k" className="ml-3">
                        <span className="block text-sm text-gray-700">
                          Less than $25K
                        </span>
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="budget-25k-50k"
                        name="budget"
                        defaultValue="25k-50k"
                        type="radio"
                        onBlur={setExpectedBudget}
                        className="focus:ring-amber-500 h-4 w-4 text-amber-600 border-gray-300"
                      />
                      <label htmlFor="budget-25k-50k" className="ml-3">
                        <span className="block text-sm text-gray-700">
                          $25K – $50K
                        </span>
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="budget-50k-100k"
                        name="budget"
                        defaultValue="50k-100k"
                        type="radio"
                        onBlur={setExpectedBudget}
                        className="focus:ring-grape-500 h-4 w-4 text-grape-600 border-gray-300"
                      />
                      <label htmlFor="budget-50k-100k" className="ml-3">
                        <span className="block text-sm text-gray-700">
                          $50K – $100K
                        </span>
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input
                        id="budget-over-100k"
                        name="budget"
                        defaultValue="over_100k"
                        type="radio"
                        onBlur={setExpectedBudget}
                        className="focus:ring-amber-500 h-4 w-4 text-amber-600 border-gray-300"
                      />
                      <label htmlFor="budget-over-100k" className="ml-3">
                        <span className="block text-sm text-gray-700">
                          $100K+
                        </span>
                      </label>
                    </div>
                  </div>
                </fieldset>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="how-did-you-hear-about-us"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {howDidYouHearAboutUsLabel}
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="how-did-you-hear-about-us"
                      id="how-did-you-hear-about-us"
                      onChange={setHowDidHear}
                      className="w-full shadow-md border-white px-5 py-3 placeholder-warm-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-amber-500 focus:ring-white rounded-md"
                    />
                  </div>
                </div>
                <div className="sm:flex sm:justify-end text-right sm:col-span-2">
                  {siteKey && (
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      badge={"bottomleft"}
                      sitekey={siteKey}
                      onErrored={() => setIsVerified(false)}
                      onExpired={() => setIsVerified(false)}
                      onChange={() => setIsVerified(true)}
                    />
                  )}

                  {!formIsSent || !isVerified ? (
                    <button
                      type="submit"
                      disabled={
                        !formFields.firstName ||
                        !formFields.email ||
                        !isVerified
                      }
                      className="items-center mt-3 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-blue-gray-900 bg-amber-400 hover:bg-amber-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-amber-600 focus:ring-amber-400 disabled:bg-gray-200 disabled:text-white sm:mt-0 sm:ml-3 sm:w-auto sm:flex-shrink-0"
                    >
                      {submitButton}
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="items-center mt-3 w-full inline-flex justify-center py-2 px-4 border border border-transparent text-base font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 bg-green-600 hover:bg-green-700 focus:ring-green-500 sm:mt-0 sm:ml-3 sm:w-auto sm:flex-shrink-0"
                    >
                      {submitButtonSuccess}
                      <CheckIcon className="h-6 w-6 ml-2 " />
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-gradient-to-l from-amber-400 to-amber-500">
        <div className="max-w-md mx-auto text-center py-12 px-4 sm:max-w-2xl sm:py-16 sm:px-6 lg:px-8 lg:py-24">
          <h2 className="text-3xl font-extrabold sm:text-4xl">
            <span className="block text-white">{ctaTitle}</span>
            <span className="block text-blue-gray-900">{ctaDescription}</span>
          </h2>
          <Link
            to={showMorePath}
            className="mt-8 w-full inline-flex items-center justify-center py-3 px-5 sm:w-auto border border-transparent text-sm font-medium rounded-md shadow-sm text-blue-gray-900 bg-white hover:bg-amber-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-text-amber-600"
          >
            {showMoreText}{" "}
            <ChevronRightIcon
              className="flex-shrink-0 w-6 h-6 text-blue-gray-900"
              aria-hidden="true"
            />
          </Link>
        </div>
      </div>
    </section>
  );
};

export default ContactsPageSection;
